<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.png" alt height="35" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="50" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge badge-danger badge-pill">{{total}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  Notificações
                </h6>
              </div>
              <!--<div class="col-auto">
                <a href="#" class="small">Limpar Tudo</a>
              </div>-->
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <a href="javascript: void(0);" @click.prevent="alertStatus(alert)" v-for="(alert, index) in alerts" :key="index" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{alert.title}}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{alert.content}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                       {{ alert.created_at | moment('DD/MM/YYYY') }} às  {{ alert.created_at | moment('HH:mm') }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <!--<a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    texto 22
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      texto 23
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      texto 24
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    texto 25
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                     texto 26
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      texto 27
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                   texto 27
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      texto 28
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      texto 29
                    </p>
                  </div>
                </div>
              </div>
            </a>-->
          </simplebar>
          <!--<div class="p-2 border-top">
            <a
              class="btn btn-sm btn-light btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              Ver Todas Notificações
            </a>
          </div>-->
        </b-dropdown>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner" class="text-info"></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <b-avatar variant="info" class="rounded-circle header-profile-user" v-if="$store.state.auth.user.avatar && $store.state.auth.user.avatar != '' && $store.state.auth.user.avatar != null" :src="$store.state.auth.user.avatar"></b-avatar>
            <b-avatar variant="info" class="rounded-circle header-profile-user" v-else src=" @/assets/images/notimg.jpg"></b-avatar>
            <span class="d-none d-xl-inline-block ml-1">{{$store.state.auth.user.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item @click.prevent="$router.push({name: 'profile'})">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i>
              Perfil
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="$router.push({name: 'financeiro'})">
            <i class="bx bx-wallet font-size-16 align-middle mr-1"></i>
            Financeiro
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <button @click.prevent="sair()" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            Sair
          </button>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import simplebar from "simplebar-vue";
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      alerts: [],
      total: 0,
      filtro:{
        page: 1,
        limit: 10
      }
    };
  },
  components: {
    simplebar,
    InfiniteLoading
  },
  mounted() {
    this.value = '';
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    ...mapActions('auth', ['ActionSignOut']),
     ...mapActions('setting', ['ActionAlertAll', 'ActionAlertUpdate']),
     async infiniteHandler($state) {
      try {
        await this.ActionAlertAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.alerts.push(...res.data.data.list)
            this.total = res.data.data.total
            $state.loaded()
          } else {
            $state.complete()
          }
        })
      } catch (_) {
        this.alerts = []
        this.total = 0
        $state.complete()
      }
    },
    async alertStatus(alert){
      try {
        alert.status = 1
        this.$router.push({path: "/"+alert.link})
        await this.ActionAlertUpdate(alert)
        alert.status = 0
        this.alerts.splice(this.alerts.indexOf(alert), 1)
        this.total = this.total - 1
      } catch (e) {}
    },
    async sair(){
      try {
          await this.ActionSignOut()
          this.$router.push({name:"login"})
      } catch (_) {}
        
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    
    logoutUser() {
     
    },
  },
};
</script>
