<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" type="dark" width="fluid" />

      <div class="main-content">
        <div class="page-content" style="min-height: 100vh">
          <div class="container-fluid">
            <transition name="fade-bottom-2x" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
        <Footer />
      </div>
      <!--<RightBar />-->
    </div>
  </div>
</template>
<style>
.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: transform 0.35s, opacity 0.28s ease-in-out;
}
.zoom-fade-enter {
  transform: scale(0.97);
  opacity: 0;
}

.zoom-fade-leave-to {
  transform: scale(1.03);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .28s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .35s, transform .4s;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateX(-30%);
}

.slide-fade-leave-to{
    opacity: 0;
    transform: translateX(30%);
}

.zoom-out-enter-active, .zoom-out-leave-active {
  transition: opacity .35s ease-in-out, transform .45s ease-out;
}
.zoom-out-enter, .zoom-out-leave-to {
  opacity: 0;
  transform: scale(0);
}

.fade-bottom-enter-active, .fade-bottom-leave-active {
  transition: opacity .3s, transform .35s;
}
.fade-bottom-enter {
  opacity: 0;
  transform: translateY(-8%);
}

.fade-bottom-leave-to{
    opacity: 0;
    transform: translateY(8%);
}

.fade-bottom-2x-enter-active, .fade-bottom-2x-leave-active {
  transition: opacity .2s, transform .25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to{
    opacity: 0;
    transform: translateY(4%);
}

.fade-top-enter-active, .fade-top-leave-active {
  transition: opacity .3s, transform .35s;
}
.fade-top-enter {
  opacity: 0;
  transform: translateY(8%);
}

.fade-top-leave-to{
    opacity: 0;
    transform: translateY(-8%);
}

.fade-top-2x-enter-active, .fade-top-2x-leave-active {
  transition: opacity .2s, transform .25s;
}
.fade-top-2x-enter {
  opacity: 0;
  transform: translateY(4%);
}

.fade-top-2x-leave-to{
    opacity: 0;
    transform: translateY(-4%);
}
</style>
<script>
import router from '@/router'
/*
import RightBar from "@/components/right-bar";
*/
import SideBar from '@/components/side-bar'
import NavBar from '@/components/nav-bar'
import Footer from '@/components/footer'
/**
 * Vertical layout
 */
export default {
  //components: { NavBar, SideBar, RightBar, Footer },
  components: {
    NavBar,
    SideBar,
    Footer,
  },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal')
    document.body.removeAttribute('data-topbar', 'dark')
    document.body.removeAttribute('data-layout-size', 'boxed')
    document.body.classList.remove('auth-body-bg')
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable')

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
          document.body.classList.remove('vertical-collpsed')
        })
        document.body.classList.toggle('vertical-collpsed')
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
        document.body.classList.remove('vertical-collpsed')
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
  },
}
</script>
